import React from 'react';

export default function GlobalErrorScript() {
    const script = `if (typeof window !== 'undefined' && typeof window.onerror !== 'undefined') {
    const original = window.onerror;

    window.onerror = function(msg, url, line, col, error) {
        original.apply(this, arguments);

        if (typeof window.ga !== 'function') {
            return;
        }

        window.ga('send', {
            hitType: 'event',
            eventCategory: 'JS Errors',
            eventAction: 'error',
            eventLabel: JSON.stringify({
                msg,
                url,
                line,
                col,
                error
            })
        });
    };
}`;

    return (
        <script dangerouslySetInnerHTML={{ __html: script }} />
    );
}
