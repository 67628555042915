/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, keywords, title }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;
    const finalTitle = title || site.siteMetadata.title;
    const finalKeywords = keywords.concat([]);

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={finalTitle}
            titleTemplate={title ? `%s | ${site.siteMetadata.title}` : null}
            meta={[
                {
                    name: 'description',
                    content: metaDescription
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: metaDescription
                },
                {
                    property: 'og:image',
                    content: '/android-chrome-1024x1024.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: metaDescription
                },
                {
                    name: 'keywords',
                    content: finalKeywords.join(', ')
                },
                {
                    name: 'robots',
                    content: 'noindex, nofollow'
                }
            ].concat(meta)}
        />
    );
}

Seo.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: [],
    description: ''
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired
};

export default Seo;
