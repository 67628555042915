import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import GlobalErrorScript from './utils/GlobalErrorScript';
import './Layout.scss';

function Layout({ children, style, className }) {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            // eslint-disable-next-line
            render={(/* data */) => (
                <main
                    style={{
                        margin: '0 auto',
                        maxWidth: 960,
                        padding: '0px 1.0875rem 1.45rem',
                        paddingTop: 0,
                        height: '100%',
                        ...style
                    }}
                    className={className}
                >
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-92KDNRZQD3" />
                    <script dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${process.env.GTM_ID}');` }}
                    />

                    <GlobalErrorScript />
                    {children}
                </main>
            )}
        />
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
};

export default Layout;
