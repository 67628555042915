import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './HouseStats.module.scss';

function HouseStats({ house, className, tag }) {
    const items = [
        house.price ? `$${house.price.toLocaleString()}` : null,
        house.beds ? `${house.beds} bed` : null,
        house.baths ? `${house.baths} bath` : null,
        house.sqft ? <Fragment>{house.sqft.toLocaleString()} ft<sup>2</sup></Fragment> : null
    ].filter(Boolean).map((item, i) => {
        return <span key={i}>{item}</span>;
    });

    const props = {
        className: classnames(styles.stats, {
            [className]: className
        })
    };

    return React.createElement(tag, props, items);
}

HouseStats.propTypes = {
    house: PropTypes.object,
    className: PropTypes.string,
    tag: PropTypes.string
};

HouseStats.defaultProps = {
    tag: 'span'
};

export default HouseStats;
