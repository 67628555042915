import { useState, useEffect } from 'react';

const VERB_TYPE_TAP = 'Tap';
const VERB_TYPE_CLICK = 'Click';

export default function useTapVerb() {
    const [tapVerb, setTabVerb] = useState(null);

    useEffect(() => {
        const isTouchDevice = 'ontouchstart' in document.documentElement;

        setTabVerb(isTouchDevice ? VERB_TYPE_TAP : VERB_TYPE_CLICK);

        window.addEventListener('touchstart', function onFirstTouch() {
            if (tapVerb !== null && tapVerb !== VERB_TYPE_TAP) {
                setTabVerb(VERB_TYPE_TAP);
            }

            window.removeEventListener('touchstart', onFirstTouch, false);
        }, false);
    }, [tapVerb]);

    return tapVerb;
}
