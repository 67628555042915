import md5 from 'md5';

export function gravatar({ email = 'seany789@gmail.com', size = 300 } = {}) {
    return `https://secure.gravatar.com/avatar/${md5(email)}?s=${size}`;
}

export function clamp(val, min, max) {
    return Math.min(Math.max(val, min), max);
}

export function getHouseLink(house) {
    return `/${house.idType}/${house.id}`;
}

// t: current time
// b: beginning value
// c: change in value (destination value - beginning value)
// d: duration
// s: overshoot ammount (optional - used only on *back easing)

/* eslint-disable */
export function easeOutBack(t, b, c, d, s) {
    if (s == undefined) s = 1.70158;
    return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
}

export function easeOutExpo(t, b, c, d) {
    return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
}
/* eslint-enable */

export function alterExternalLinks() {
    Array.from(document.querySelectorAll('a')).forEach((link) => {
        if (link.hostname !== window.location.hostname) {
            link.target = '_blank';
            link.rel = 'nofollow noopener';
        }
    });
}

export function fixTabFocus() {
    document.body.addEventListener('keydown', (e) => {
        // Tab
        if (e.which === 9) {
            document.body.classList.add('showfocus');
        }
    });

    document.body.addEventListener('click', () => {
        document.body.classList.remove('showfocus');
    });
}

// Inclusive
export function randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomItem(arr) {
    return arr[randomNumberBetween(0, arr.length - 1)];
}

// https://stackoverflow.com/a/27078401/1048847
export function throttle(func, wait, options) {
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;

    if (!options) {
        options = {};
    }

    // eslint-disable-next-line
    const later = function() {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);

        if (!timeout) {
            context = null;
            args = null;
        }
    };

    return function() {
        const now = Date.now();

        if (!previous && options.leading === false) {
            previous = now;
        }
        const remaining = wait - (now - previous);

        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        }
        else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

export function loadScript(src, id, appendTo) {
    if (!src || !id) {
        throw new Error('Must provide a src to load and an id');
    }

    if (!appendTo) {
        appendTo = document.body;
    }

    return new Promise((resolve, reject) => {
        let script = document.getElementById(id);
        let counter = 40;

        // Set a timer because if we're here, that means another script has
        // already loaded this script src but it hasnt loaded yet. In that
        // case we need to wait for data-loaded to be set before calling
        // the scripLoaded callback
        const checkLoaded = () => { // eslint-disable-line
            counter -= 1;

            // Just reject if it's been about 40 seconds with no load
            if (counter === 0) {
                reject(`Could not load ${src} after 40 seconds`);
                return;
            }

            if (script.getAttribute('data-loaded')) {
                resolve({
                    firstLoad: false
                });
                return;
            }

            setTimeout(checkLoaded, 1000);
        };

        if (script) {
            checkLoaded();
            return;
        }

        script = document.createElement('script');

        script.id = id;
        script.onload = () => {
            script.setAttribute('data-loaded', true);
            resolve({
                firstLoad: true
            });
        };
        script.onerror = reject;
        script.src = src;

        appendTo.appendChild(script);
    });
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // eslint-disable-line
}

export function interpolate(start, end, steps, count) {
    const s = start;
    const e = end;
    const final = s + (((e - s) / steps) * count);
    return Math.floor(final);
}

export class Color {
    constructor(r, g, b) {
        this.r = r;
        this.g = g;
        this.b = b;
    }

    getColors() {
        return {
            r: this.r,
            g: this.g,
            b: this.b
        };
    }
}

export function getColorRange(startColor, endColor, howMany) {
    const color1 = new Color(...hexToRgb(startColor));
    const color2 = new Color(...hexToRgb(endColor));
    const count = howMany - 1;
    const range = [];

    for (let i = 0, len = howMany; i < len; i++) {
        const r = interpolate(color1.r, color2.r, count, i);
        const g = interpolate(color1.g, color2.g, count, i);
        const b = interpolate(color1.b, color2.b, count, i);

        range.push(rgbToHex(r, g, b));
    }

    return range;
}

// Having scripts in the markdown files dont work.
export function reAppendScripts(containerId) {
    const container = document.getElementById(containerId);
    const scripts = Array.from(container.querySelectorAll('script'));

    let promise = Promise.resolve();
    const newScriptIds = [];

    scripts.forEach((script, i) => {
        // let previousNonScript = script.previousElementSibling;

        // while (previousNonScript && previousNonScript.tagName.toLowerCase() === 'script') {
        //     previousNonScript = previousNonScript.previousElementSibling;
        // }

        const src = script.src;
        const hasSrc = !!script.src;
        const html = script.innerHTML;
        const isRelativePath = hasSrc && src.indexOf('/') === 0;
        const isRemotePath = hasSrc && src.indexOf('http') === 0;
        let id;

        if (hasSrc && !(isRelativePath || isRemotePath)) {
            return;
        }

        script.parentNode.removeChild(script);

        if (src) {
            id = src.replace(/\W+/g, '');
            newScriptIds.push(id);
        }

        if (id) {
            promise = promise.then(() => {
                return loadScript(src, id);
            });

            return;
        }

        const newScript = document.createElement('script');
        newScript.innerHTML = html;
        const scriptId = `markdownscript${i}`;
        newScript.id = scriptId;

        newScriptIds.push(scriptId);

        document.body.appendChild(newScript);

        // previousNonScript.insertAdjacentHTML('afterend', newScript.outerHTML);
    });

    return promise.then(() => {
        return newScriptIds;
    });
}
