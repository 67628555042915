import React from 'react';
import PropTypes from 'prop-types';

function HouseLikes({ house, className, tag, prefix }) {
    const props = {
        className: className
    };

    if (!house.pros) {
        return null;
    }

    const formatted = house.pros.split(',').map((s) => s.trim()).join(', ');

    return React.createElement(tag, props, `${prefix}${formatted}`);
}

HouseLikes.propTypes = {
    house: PropTypes.object,
    className: PropTypes.string,
    prefix: PropTypes.string,
    tag: PropTypes.string
};

HouseLikes.defaultProps = {
    tag: 'span',
    prefix: ''
};

export default HouseLikes;
